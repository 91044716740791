













































































































































































import { Component, Inject, Vue } from "vue-property-decorator";
import FileSelection from "@/components/common/FileSelection.vue";
import TasksList from "@/components/tasks/TasksList.vue";

import { BackendClient } from "ibf-backend";

@Component({
  components: {
    FileSelection,
    TasksList,
  },
})
export default class CutsetAnalyses extends Vue {
  @Inject("backendClient") client!: BackendClient;

  private valid: boolean = true;

  private modeltypes: any = [
    { text: "EN (Nouveau Nucléaire)", value: "EN" },
    { text: "FR (Parc)", value: "FR" },
  ];

  private selectedModelType: string = "EN";

  defaultParams = {
    EN: {
      stateAttribute: "POS",
      feiAttribute: "FIE",
      eiAttribute: "IE",
      excludedSuffix: "NA",
      sysAttribute: "SYS",
      failureModeAttribute: "FM",
      matAttribute: "MAT",
      analyseAttribute: "HRA",
      detectStateInIE: true,
    },
    FR: {
      stateAttribute: "ETAT",
      feiAttribute: "FEI",
      eiAttribute: "EI",
      excludedSuffix: "NA",
      sysAttribute: "SYS",
      failureModeAttribute: "MD",
      matAttribute: "COMP",
      analyseAttribute: "EPFH",
      detectStateInIE: true,
    },
  };

  private analysis: any = {
    selectedAnalyses: {
      resultAnalysis: true,
      modelAnalysis: false,
      cutsetAnalysis: false,
    },
    modelFileDescriptor: null,
    cutsetFileDescriptor: null,
    attributeParams: this.defaultParams.EN,
  };

  private requiredRule(value: string): any {
    return !!value || "Required.";
  }

  private taskCreating: boolean = false;

  private async launchAnalysis() {
    this.taskCreating = true;

    await this.client.taskApi.createTask({
      type: "CutSetAnalysis",
      parameters: this.analysis,
      files: [
        {
          name: this.analysis.modelFileDescriptor.name,
          context: "USER",
          path: this.analysis.modelFileDescriptor.uri,
        },
        {
          name: this.analysis.cutsetFileDescriptor.name,
          context: "USER",
          path: this.analysis.cutsetFileDescriptor.uri,
        },
      ],
    });

    this.taskCreating = false;
  }

  private changeModelType(value: any) {
    this.analysis.attributeParams = this.defaultParams[value];
  }
}
